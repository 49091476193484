import React, { useEffect, useState } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import './App.css';
import AboutUs from './pages/AboutUs/AboutUs';
import PublicHousingRentCalculator from './pages/PublicHousingRentCalculator/PublicHousingRentCalculator';
import MobileNav from './components/MobileNav';
import './components/MobileNav.css';
import { setupScrollHandler, setupScrollAnimations } from './NavToggle';
import logoImg from './assets/1mins_logo-removebg.png';

// 在App頁面加載時調用的函數
function useAppSetup() {
  useEffect(() => {
    // 設置滾動處理
    setupScrollHandler();
    
    // 設置滾動動畫
    setupScrollAnimations();
  }, []);
}

// 主頁組件
const HomePage = () => {
  return (
    <main className="page-transition" style={{ backgroundColor: '#f9fafc' }}>
      <Helmet>
        <title>1Mins.ai | AI創作，一分鐘見效</title>
        <meta name="description" content="1Mins.ai 是一個智能、快速、高效的AI創作平台，讓您的創意在一分鐘內實現。" />
        <meta name="keywords" content="AI創作,人工智能,智能寫作,圖像生成,內容翻譯,數據分析" />
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="1Mins.ai | AI創作，一分鐘見效" />
        <meta property="og:description" content="智能、快速、高效的AI創作平台，讓您的創意在一分鐘內實現。" />
        <meta property="og:image" content="/social-preview-home.jpg" />
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="1Mins.ai | AI創作，一分鐘見效" />
        <meta name="twitter:description" content="智能、快速、高效的AI創作平台，讓您的創意在一分鐘內實現。" />
      </Helmet>
      
      <section className="hero" style={{ backgroundColor: '#e6f0ff' }}>
        <div className="container">
          <div className="hero-content">
            <h2 style={{ color: '#333' }}>AI創作，一分鐘見效</h2>
            <p style={{ color: '#555' }}>以前需要1個鐘，現在只需要1分鐘</p>
{/*             <div className="hero-buttons">
              <a href="#start" className="btn btn-primary">開始體驗</a>
              <a href="#learn" className="btn btn-secondary">了解更多</a>
            </div> */}
          </div>
        </div>
      </section>

      <section className="features" id="features" style={{ backgroundColor: '#f9fafc' }}>
        <div className="container">
          <div className="section-header">
            <h2 style={{ color: '#333' }}>涉獵多樣化的AI功能</h2>
          </div>
          
          <div className="feature-cards">
            <div className="feature-card" style={{ 
              backgroundColor: '#fff', 
              boxShadow: '0 3px 10px rgba(0, 0, 0, 0.05)',
              borderRadius: '10px',
              border: '1px solid #eee'
            }}>
              <div className="feature-icon">✍️</div>
              <h3 style={{ color: '#4D91FF' }}>智能寫作</h3>
              <p style={{ color: '#555' }}>利用先進的語言模型，快速生成高質量的文章、報告、廣告文案等內容。</p>
            </div>
            
            <div className="feature-card" style={{ 
              backgroundColor: '#fff', 
              boxShadow: '0 3px 10px rgba(0, 0, 0, 0.05)',
              borderRadius: '10px',
              border: '1px solid #eee'
            }}>
              <div className="feature-icon">🎨</div>
              <h3 style={{ color: '#4D91FF' }}>圖像生成</h3>
              <p style={{ color: '#555' }}>通過簡單的文字描述，創造出精美的藝術作品、插圖和產品設計圖。</p>
            </div>
            
            <div className="feature-card" style={{ 
              backgroundColor: '#fff', 
              boxShadow: '0 3px 10px rgba(0, 0, 0, 0.05)',
              borderRadius: '10px',
              border: '1px solid #eee'
            }}>
              <div className="feature-icon">🔄</div>
              <h3 style={{ color: '#4D91FF' }}>自動化</h3>
              <p style={{ color: '#555' }}>自動化您的工作流程，節省時間和精力。</p>
            </div>
            
            <div className="feature-card" style={{ 
              backgroundColor: '#fff', 
              boxShadow: '0 3px 10px rgba(0, 0, 0, 0.05)',
              borderRadius: '10px',
              border: '1px solid #eee'
            }}>
              <div className="feature-icon">📊</div>
              <h3 style={{ color: '#4D91FF' }}>SEO分析</h3>
              <p style={{ color: '#555' }}>通過SEO分析您的內容效果，提供有價值的洞察和優化建議。</p>
            </div>
          </div>
        </div>
      </section>

      <section className="ecosystem" id="ecosystem" style={{ backgroundColor: '#e6f0ff' }}>
        <div className="container">
          <div className="section-header">
            <h2 style={{ color: '#333' }}>完整的AI生態系統</h2>
            <p style={{ color: '#555' }}>一站式滿足您的所有創作需求</p>
          </div>
          
          <div className="ecosystem-grid">
            <div className="ecosystem-item" style={{ 
              backgroundColor: '#fff', 
              boxShadow: '0 3px 10px rgba(0, 0, 0, 0.05)',
              borderRadius: '10px',
              padding: '20px',
              border: '1px solid #eee'
            }}>
              <h3 style={{ color: '#4D91FF' }}>內容創作</h3>
              <p style={{ color: '#555' }}>各類型文字內容的生成與優化，包括文章、報告、廣告文案、社交媒體貼文等。</p>
            </div>
            
            <div className="ecosystem-item" style={{ 
              backgroundColor: '#fff', 
              boxShadow: '0 3px 10px rgba(0, 0, 0, 0.05)',
              borderRadius: '10px',
              padding: '20px',
              border: '1px solid #eee'
            }}>
              <h3 style={{ color: '#4D91FF' }}>視覺設計</h3>
              <p style={{ color: '#555' }}>生成與編輯圖像、插圖、Logo、產品設計、UI/UX元素等視覺內容。</p>
            </div>
            
            <div className="ecosystem-item" style={{ 
              backgroundColor: '#fff', 
              boxShadow: '0 3px 10px rgba(0, 0, 0, 0.05)',
              borderRadius: '10px',
              padding: '20px',
              border: '1px solid #eee'
            }}>
              <h3 style={{ color: '#4D91FF' }}>多媒體處理</h3>
              <p style={{ color: '#555' }}>音頻生成、編輯和處理，包括配音、音樂創作和聲音效果。</p>
            </div>
            
            <div className="ecosystem-item" style={{ 
              backgroundColor: '#fff', 
              boxShadow: '0 3px 10px rgba(0, 0, 0, 0.05)',
              borderRadius: '10px',
              padding: '20px',
              border: '1px solid #eee'
            }}>
              <h3 style={{ color: '#4D91FF' }}>數據智能</h3>
              <p style={{ color: '#555' }}>內容效果分析、用戶行為預測、市場趨勢洞察和數據可視化。</p>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

function App() {
  // 使用React狀態控制菜單開關
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  
  // 切換菜單狀態
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };
  
  // 當點擊外部區域時關閉菜單
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        mobileMenuOpen && 
        !e.target.closest('.hamburger') && 
        !e.target.closest('.mobile-menu')
      ) {
        setMobileMenuOpen(false);
      }
    };
    
    document.addEventListener('click', handleClickOutside);
    
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [mobileMenuOpen]);
  
  // 當窗口大小變化時管理菜單狀態
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768 && mobileMenuOpen) {
        setMobileMenuOpen(false);
      }
    };
    
    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [mobileMenuOpen]);
  
  useAppSetup();
  
  return (
    <HelmetProvider>
      <div className="app" style={{ backgroundColor: '#f9fafc' }}>
        <header className="header" style={{ 
          backgroundColor: '#fff',
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.05)',
          color: '#333',
          display: 'none'
        }}>
          <div className="container">
            <div className="logo">
              <Link to="/">
                <img src={logoImg} alt="1Mins.ai Logo" width="50" />
              </Link>
              {/* <h1 style={{ color: '#4D91FF' }}>1Mins.ai</h1> */}
            </div>
            
            {/* 桌面導航 - 只在大屏幕顯示 */}
            <nav className="main-nav desktop-only">
              <ul>
                <li>
                  <Link to="/" style={{ color: '#333' }}>首頁</Link>
                </li>
                <li>
                  <Link to="/rent-calculator" style={{ color: '#333' }}>公屋租金計算器</Link>
                </li>
                <li>
                  <Link to="/about" style={{ color: '#333' }}>關於我們</Link>
                </li>
               
              </ul>
            </nav>
            
            {/* 漢堡菜單按鈕 - 移動端顯示 */}
            <div 
              className={`hamburger ${mobileMenuOpen ? 'active' : ''}`} 
              onClick={toggleMobileMenu}
              style={{ color: '#333' }}
            >
              <span style={{ backgroundColor: '#333' }}></span>
              <span style={{ backgroundColor: '#333' }}></span>
              <span style={{ backgroundColor: '#333' }}></span>
            </div>
          </div>
        </header>

        {/* 移動端導航菜單 */}
        <MobileNav isOpen={mobileMenuOpen} toggleMenu={setMobileMenuOpen} />

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/rent-calculator" element={<PublicHousingRentCalculator />} />
        </Routes>

        {/* <footer className="footer" style={{ 
          backgroundColor: '#fff',
          borderTop: '1px solid #eee',
          color: '#333'
        }}>
          <div className="container">
            <div className="footer-grid">
              <div className="footer-col">
                <h3 style={{ color: '#4D91FF' }}>1Mins.ai</h3>
                <p>智能、快速、高效的AI創作平台，讓您的創意在一分鐘內實現。</p>
              </div>
              
              <div className="footer-col">
                <h4 style={{ color: '#4D91FF' }}>產品</h4>
                <ul>
                  <li><a href="#ai-writing" style={{ color: '#555' }}>智能寫作</a></li>
                  <li><a href="#image-generation" style={{ color: '#555' }}>圖像生成</a></li>
                  <li><a href="#content-translation" style={{ color: '#555' }}>內容翻譯</a></li>
                  <li><a href="#data-analysis" style={{ color: '#555' }}>數據分析</a></li>
                </ul>
              </div>
              
              <div className="footer-col">
                <h4 style={{ color: '#4D91FF' }}>資源</h4>
                <ul>
                  <li><a href="#blog" style={{ color: '#555' }}>部落格</a></li>
                  <li><a href="#tutorials" style={{ color: '#555' }}>教程</a></li>
                  <li><a href="#case-studies" style={{ color: '#555' }}>案例研究</a></li>
                  <li><a href="#documentation" style={{ color: '#555' }}>文檔</a></li>
                </ul>
              </div>
              
              <div className="footer-col">
                <h4 style={{ color: '#4D91FF' }}>聯繫我們</h4>
                <ul>
                  <li><a href="#support" style={{ color: '#555' }}>客戶支持</a></li>
                  <li><a href="#sales" style={{ color: '#555' }}>銷售咨詢</a></li>
                  <li><a href="#partnerships" style={{ color: '#555' }}>合作機會</a></li>
                  <li><a href="#careers" style={{ color: '#555' }}>加入我們</a></li>
                </ul>
              </div>
            </div>
            
            <div className="footer-bottom">
              <p style={{ color: '#999' }}>© 2025 1Mins.ai. 保留所有權利。</p>
            </div>
          </div>
        </footer> */}
      </div>
    </HelmetProvider>
  );
}

export default App;
