// 移動導航菜單切換功能
export const setupNavToggle = () => {
  // 使用setTimeout確保DOM已完全加載
  setTimeout(() => {
    const mobileNavToggle = document.querySelector('.mobile-nav-toggle');
    const mainNav = document.querySelector('.main-nav');
    
    if (mobileNavToggle && mainNav) {
      // 清除可能已有的事件監聽器
      const newMobileNavToggle = mobileNavToggle.cloneNode(true);
      mobileNavToggle.parentNode.replaceChild(newMobileNavToggle, mobileNavToggle);
      
      newMobileNavToggle.addEventListener('click', () => {
        mainNav.classList.toggle('active');
        
        // 添加動畫效果到漢堡菜單
        const spans = newMobileNavToggle.querySelectorAll('span');
        if (mainNav.classList.contains('active')) {
          spans[0].style.transform = 'translateY(8px) rotate(45deg)';
          spans[1].style.opacity = '0';
          spans[2].style.transform = 'translateY(-8px) rotate(-45deg)';
        } else {
          spans[0].style.transform = 'none';
          spans[1].style.opacity = '1';
          spans[2].style.transform = 'none';
          
          // 關閉所有下拉選單
          document.querySelectorAll('.dropdown').forEach(dropdown => {
            dropdown.classList.remove('active');
          });
        }
      });
      
      // 處理移動端下拉菜單
      document.querySelectorAll('.dropdown > a').forEach(link => {
        // 清除可能已有的事件監聽器
        const newLink = link.cloneNode(true);
        link.parentNode.replaceChild(newLink, link);
        
        newLink.addEventListener('click', (e) => {
          if (window.innerWidth <= 768) {
            e.preventDefault();
            e.stopPropagation();
            const parent = newLink.parentElement;
            
            // 切換當前菜單的激活狀態
            parent.classList.toggle('active');

            // 如果當前菜單被激活，關閉其他菜單
            if (parent.classList.contains('active')) {
              document.querySelectorAll('.dropdown').forEach(dropdown => {
                if (dropdown !== parent) {
                  dropdown.classList.remove('active');
                }
              });
            }
          }
        });
      });
      
      // 點擊導航鏈接時關閉菜單
      document.querySelectorAll('.dropdown-content a, .main-nav a:not(.dropdown > a)').forEach(link => {
        // 清除可能已有的事件監聽器
        const newLink = link.cloneNode(true);
        link.parentNode.replaceChild(newLink, link);
        
        newLink.addEventListener('click', () => {
          if (window.innerWidth <= 768) {
            mainNav.classList.remove('active');
            const spans = newMobileNavToggle.querySelectorAll('span');
            spans[0].style.transform = 'none';
            spans[1].style.opacity = '1';
            spans[2].style.transform = 'none';
            
            // 關閉所有下拉菜單
            document.querySelectorAll('.dropdown').forEach(dropdown => {
              dropdown.classList.remove('active');
            });
          }
        });
      });
      
      // 處理窗口大小變化
      window.addEventListener('resize', () => {
        if (window.innerWidth > 768) {
          // 恢復桌面版樣式
          document.querySelectorAll('.dropdown').forEach(dropdown => {
            dropdown.classList.remove('active');
          });
          
          mainNav.classList.remove('active');
          const spans = newMobileNavToggle.querySelectorAll('span');
          spans[0].style.transform = 'none';
          spans[1].style.opacity = '1';
          spans[2].style.transform = 'none';
        }
      });

      // 確保點擊頁面其他部分時關閉菜單
      document.addEventListener('click', (e) => {
        if (window.innerWidth <= 768) {
          if (!e.target.closest('.dropdown') && !e.target.closest('.mobile-nav-toggle') && mainNav.classList.contains('active')) {
            // 不關閉主導航，只關閉下拉菜單
            document.querySelectorAll('.dropdown').forEach(dropdown => {
              dropdown.classList.remove('active');
            });
          }
        }
      });
    }
  }, 500); // 給予500ms延遲以確保DOM已完全加載
};

// 滾動時處理導航欄樣式
export const setupScrollHandler = () => {
  const header = document.querySelector('.header');
  
  if (header) {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 50) {
        header.style.padding = '15px 0';
        header.style.backgroundColor = 'rgba(0, 0, 0, 0.95)';
      } else {
        header.style.padding = '20px 0';
        header.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
      }
    });
  }
};

// 添加滾動動畫效果
export const setupScrollAnimations = () => {
  const elements = document.querySelectorAll('.feature-card, .ecosystem-item, .section-header, .member-card');
  
  const checkVisible = () => {
    elements.forEach(element => {
      const elementPosition = element.getBoundingClientRect().top;
      const screenPosition = window.innerHeight / 1.3;
      
      if (elementPosition < screenPosition) {
        element.style.opacity = '1';
        element.style.transform = 'translateY(0)';
      }
    });
  };
  
  // 初始化樣式
  elements.forEach(element => {
    element.style.opacity = '0';
    element.style.transform = 'translateY(20px)';
    element.style.transition = 'all 0.6s ease';
  });
  
  // 監聽滾動事件
  window.addEventListener('scroll', checkVisible);
  
  // 初始檢查
  checkVisible();
}; 