import React from 'react';
import { Helmet } from 'react-helmet-async';
import './AboutUs.css';
import issacImg from '../../assets/IssacPropic.png';
import kyleImg from '../../assets/KylePropic.png';
import samImg from '../../assets/SamProPic.png';

// 如果圖片未能載入，可以使用以下備選方案
// const issacImg = 'https://via.placeholder.com/300';
// const kyleImg = 'https://via.placeholder.com/300';
// const samImg = 'https://via.placeholder.com/300';

const AboutUs = () => {
  const teamMembers = [
    {
      id: 1,
      name: 'Kyle',
      role: 'Marketing 專家',
      image: kyleImg,
      description: 'Kyle在市場營銷領域擁有深厚的專業知識，尤其擅長數字營銷策略和品牌建設。他負責1Mins.ai的市場推廣和品牌發展，通過創新的營銷活動和策略合作，提升品牌知名度並吸引目標用戶群體。',
      skills: ['數字營銷', '品牌策略', '市場分析', '社交媒體']
    },
    {
      id: 2,
      name: 'Sam',
      role: 'SEO 專家',
      image: samImg,
      description: 'Sam是一位經驗豐富的SEO專家，在優化數字內容和提高網站可見度方面擁有卓越的技能。他的策略使1Mins.ai在競爭激烈的市場中脫穎而出，確保我們的服務能夠觸達更多需要AI創作解決方案的用戶。',
      skills: ['搜索引擎優化', '內容策略', '數據分析', '用戶體驗']
    },
    {
      id: 3,
      name: 'Issac',
      role: 'IT 從業者',
      image: issacImg,
      description: '擁有多年IT行業經驗，專注於人工智能和自動化的開發和應用。Issac領導團隊構建了強大而靈活的AI創作平台，確保用戶能夠獲得最佳的技術體驗。',
      skills: ['人工智能', '系統架構', '全端開發', '自動化']
    }
  ];

  return (
    <main className="page-transition">
      <Helmet>
        <title>關於我們 | 1Mins.ai</title>
        <meta name="description" content="認識1Mins.ai背後的精英團隊，由技術、SEO和營銷專家組成，致力於提供最先進的AI創作解決方案。" />
        <meta name="keywords" content="1Mins.ai,團隊介紹,AI創作,人工智能,技術團隊" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="認識1Mins.ai團隊 | 關於我們" />
        <meta property="og:description" content="認識1Mins.ai背後的精英團隊，致力於為創作者提供最先進的AI解決方案。" />
        <meta property="og:image" content="/social-preview-about.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="認識1Mins.ai團隊 | 關於我們" />
        <meta name="twitter:description" content="認識1Mins.ai背後的精英團隊，致力於為創作者提供最先進的AI解決方案。" />
      </Helmet>
      
      <section className="about-us" id="about-us">
        <div className="container">
          <div className="section-header">
            <h2>關於我們</h2>
            <p>認識1Mins.ai背後的精英團隊</p>
          </div>
          
          <div className="team-intro">
            <p>
              我們是一支由技術、SEO和營銷專家組成的團隊，致力於為創作者提供最先進的AI解決方案。
              1Mins.ai的使命是通過人工智能簡化創作過程，讓每個人都能輕鬆地實現創意想法。
            </p>
          </div>
          
          <div className="team-members">
            {teamMembers.map(member => (
              <div className="member-card" key={member.id}>
                <div className="member-image">
                  <img src={member.image} alt={member.name} />
                </div>
                <div className="member-info">
                  <h3>{member.name}</h3>
                  <h4>{member.role}</h4>
                  <p>{member.description}</p>
                  <div className="member-skills">
                    {member.skills.map((skill, index) => (
                      <span key={index} className="skill-tag">{skill}</span>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </main>
  );
};

export default AboutUs; 