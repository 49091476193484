import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet-async";
import "./PublicHousingRentCalculator.css";
import logo from "../../assets/1mins_logo-removebg.png"; // 修正Logo圖片的路徑

function PublicHousingRentCalculator() {
  // 狀態管理
  const [currentRent, setCurrentRent] = useState("");
  const [multiplier, setMultiplier] = useState("1");
  const [familySize, setFamilySize] = useState("1");
  const [familyIncome, setFamilyIncome] = useState("");
  const [calculationResult, setCalculationResult] = useState(null);
  const [buttonHover, setButtonHover] = useState(false);

  // refs
  const resultRef = useRef(null);


  // 入息限額表
  const incomeLimits = {
    1: 13090,
    2: 20230,
    3: 25100,
    4: 31000,
    5: 38650,
    6: 45440,
    7: 49930,
    8: 55830,
    9: 61570,
    10: 67180,
  };

  // 處理計算
  const calculate = () => {
    // 驗證輸入
    const currentRentValue = parseFloat(currentRent);
    const multiplierValue = parseFloat(multiplier);
    const familySizeValue = parseInt(familySize);
    const familyIncomeValue = parseFloat(familyIncome);

    if (isNaN(currentRentValue) || isNaN(familyIncomeValue)) {
      alert("請輸入有效的租金及收入金額");
      return;
    }

    // 計算本來租金 (如果已是倍數租金)
    const baseRent = currentRentValue / multiplierValue;

    // 取得入息限額
    const limit = incomeLimits[familySizeValue];

    // 計算超額倍數
    const multipleTimes = familyIncomeValue / limit;

    // 決定新租金倍數
    let newMultiplier = 1;
    let needToMove = false;
    let yearsToMove = "";

    if (multipleTimes <= 2) {
      newMultiplier = 1; // 正常租金
    } else if (multipleTimes > 2 && multipleTimes <= 3) {
      newMultiplier = 2.5; // 新制下的2.5倍租金
    } else if (multipleTimes > 3 && multipleTimes <= 4) {
      newMultiplier = 3.5; // 新制下的3.5倍租金
    } else if (multipleTimes > 4) {
      newMultiplier = 4.5; // 新制下的4.5倍租金
      needToMove = true;
      yearsToMove = "4年後須遷出公屋";
    }

    // 計算新租金
    const newRent = baseRent * newMultiplier;

    // 設置結果
    setCalculationResult({
      baseRent,
      familySize: familySizeValue,
      limit,
      familyIncome: familyIncomeValue,
      multipleTimes,
      newMultiplier,
      newRent,
      needToMove,
      yearsToMove,
    });

    // 滾動到結果區域
    setTimeout(() => {
      resultRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  // 基於1MINS logo的顏色系統
  const colors = {
    primary: "#4D91FF", // 主站藍色
    primaryLight: "#6AA5FF",
    primaryDark: "#3A7FE6",
    secondary: "#B859FF", // 主站紫色
    secondaryLight: "#CA85FF",
    secondaryDark: "#9941E6",
    accent: "#17E7E7", // 主站青色
    accentLight: "#7FFFFF",
    accentDark: "#14C8C8",
    gradient: "linear-gradient(to right, #4D91FF, #17E7E7, #B859FF)", // 主站漸變色
    gradientLight: "linear-gradient(to right, rgba(77, 145, 255, 0.15), rgba(23, 231, 231, 0.15), rgba(184, 89, 255, 0.15))",
    dark: "#333333", // 深色文字
    light: "#ffffff", // 淺色背景
    gray: "#f5f5f5", // 淺灰背景
    grayLight: "#f0f0f0", // 中等灰色
    grayLighter: "#f9f9f9", // 淺灰色
    text: "#333333",
    textLight: "#555555",
    textDark: "#111111",
    background: "#f9fafc", // 淺色主題背景
    backgroundLight: "#ffffff",
    border: "#eeeeee",
  };

  return (
    <>
      <Helmet>
        <title>【公屋資產租金計算器2025-2026】公屋富戶、公屋入息計算方法詳細了解</title>
        <meta name="description" content="使用我們的公屋租金計算機，快速計算您的預期公屋租金！只需輸入入息與月租，即可了解根據最新公屋富戶政策的租金變化，立即試用！" data-react-helmet="true" data-rh="true" />
        <meta name="keywords" content="公屋租金計算器,公屋富戶,四級制,富戶政策,房委會,公屋入息限額,公屋資產限額" />
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="【公屋資產租金計算器2025-2026】公屋富戶、公屋入息計算方法詳細了解" />
        <meta property="og:description" content="使用我們的公屋租金計算機，快速計算您的預期公屋租金！只需輸入入息與月租，即可了解根據最新公屋富戶政策的租金變化，立即試用！" data-react-helmet="true" data-rh="true" />
        <meta property="og:image" content="/social-preview-rent-calculator.jpg" />
        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content="【公屋資產租金計算器2025-2026】公屋富戶、公屋入息計算方法詳細了解" />
        <meta property="twitter:description" content="使用我們的公屋租金計算機，快速計算您的預期公屋租金！只需輸入入息與月租，即可了解根據最新公屋富戶政策的租金變化，立即試用！" data-react-helmet="true" data-rh="true" />
        {/* 其他重要meta標籤 */}
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="theme-color" content="#f9fafc" />
        <meta name="author" content="1Mins.ai" />
        <meta name="robots" content="index, follow" />
        <meta httpEquiv="Content-Language" content="zh-hk" />
      </Helmet>
      <div className="page-transition rent-calculator-container" 
      style={{
        backgroundColor: "#f9fafc",
        color: "#333",
        padding: "20px",
        minHeight: "100vh"
      }}>

      <div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "20px"
      }}>
        <img src={logo} alt="1MINS Logo" style={{ width: "80px", marginTop: "20px" }} />
        <h1 style={{
          background: "linear-gradient(to right, #4D91FF, #17E7E7, #B859FF)",
          WebkitBackgroundClip: "text",
          backgroundClip: "text",
          color: "transparent",
          textAlign: "center",
          padding: "15px 0",
          fontSize: "1.8em",
          fontWeight: "600",
          margin: "10px 0 20px 0"
        }}>公屋富戶租金計算器</h1>
      </div>

      <div style={{
        background: "#fff",
        borderRadius: "10px",
        boxShadow: "0 3px 10px rgba(0, 0, 0, 0.05)",
        padding: "20px",
        width: "100%",
        maxWidth: "700px",
        margin: "0 auto",
        border: "1px solid #eee"
      }}>
        <div style={{
          background: "linear-gradient(to right, rgba(77, 145, 255, 0.07), rgba(23, 231, 231, 0.07), rgba(184, 89, 255, 0.07))",
          borderRadius: "10px",
          padding: "15px",
          marginBottom: "20px",
          color: "#333",
          border: "1px solid rgba(77, 145, 255, 0.2)",
          fontSize: "0.9em",
          lineHeight: "1.6"
        }}>
          根據房委會新建議的【富戶政策四級制】，公屋富戶的租金將會分為2.5倍、3.5倍及4.5倍。<br></br>
          當中受影響的公屋富戶將預計有大約4萬戶。<br></br>
          如需繳付4.5倍租金的公屋富戶在4年後依舊超額，則需要遷出。<br></br>
          如入息超限額5倍租金的公屋富戶，則需要立即遷出。<br></br>
          此計算器將會幫你簡單計算新政策下的公屋每月租金。
        </div>
        

        <div className="md-card" style={{
          backgroundColor: "#fff",
          borderRadius: "10px",
          padding: "20px",
          boxShadow: "0 3px 10px rgba(0, 0, 0, 0.05)",
          border: "1px solid #eee"
        }}>
          <div style={{ marginBottom: 15 }}>
            <label
              style={{
                display: "block",
                marginBottom: "5px",
                fontWeight: "bold",
                color: "rgb(52, 81, 175)"
              }}
              htmlFor="currentRent"
            >
              現時每月公屋租金 (HK$):
            </label>
            <input
              type="number"
              id="currentRent"
              placeholder="例如：5000"
              value={currentRent}
              onChange={(e) => setCurrentRent(e.target.value)}
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "5px",
                border: "1px solid #ddd",
                fontSize: "0.95em",
                backgroundColor: "#fff",
                color: "#333"
              }}
            />
          </div>

          <div style={{ marginBottom: 15 }}>
            <label
             style={{
              display: "block",
              marginBottom: "5px",
              fontWeight: "bold",
              color: "rgb(52, 81, 175)"
            }}
              htmlFor="multiples"
            >
              現時是否已繳付倍數公屋租金:
            </label>
            <select
              id="multiples"
              value={multiplier}
              onChange={(e) => setMultiplier(e.target.value)}
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "5px",
                border: "1px solid #ddd",
                fontSize: "0.95em",
                backgroundColor: "#fff",
                color: "#333"
              }}
            >
              <option value="1">否，繳付正常租金</option>
              <option value="1.5">是，繳付1.5倍租金</option>
              <option value="2">是，繳付2倍租金</option>
            </select>
          </div>

          <div style={{ marginBottom: 15 }}>
            <label
             style={{
              display: "block",
              marginBottom: "5px",
              fontWeight: "bold",
              color: "rgb(52, 81, 175)"
            }}
              htmlFor="familySize"
            >
              家庭成員人數:
            </label>
            <select
              id="familySize"
              value={familySize}
              onChange={(e) => setFamilySize(e.target.value)}
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "5px",
                border: "1px solid #ddd",
                fontSize: "0.95em",
                backgroundColor: "#fff",
                color: "#333"
              }}
            >
              <option value="1">1人</option>
              <option value="2">2人</option>
              <option value="3">3人</option>
              <option value="4">4人</option>
              <option value="5">5人</option>
              <option value="6">6人</option>
              <option value="7">7人</option>
              <option value="8">8人</option>
              <option value="9">9人</option>
              <option value="10">10人或以上</option>
            </select>
          </div>

          <div style={{ marginBottom: 20 }}>
            <label
              style={{
                display: "block",
                marginBottom: "5px",
                fontWeight: "bold",
                color: "rgb(52, 81, 175)"
              }}
              htmlFor="familyIncome"
            >
              家庭每月總收入 (HK$):
            </label>
            <input
              type="number"
              id="familyIncome"
              placeholder="例如：25000"
              value={familyIncome}
              onChange={(e) => setFamilyIncome(e.target.value)}
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "5px",
                border: "1px solid #ddd",
                fontSize: "0.95em",
                backgroundColor: "#fff",
                color: "#333"
              }}
            />
          </div>

          <button 
            onClick={calculate}
            onMouseEnter={() => setButtonHover(true)}
            onMouseLeave={() => setButtonHover(false)}
            style={{
              background: colors.gradient,
              color: "white",
              border: "none",
              padding: "14px 20px",
              borderRadius: 8,
              cursor: "pointer",
              fontSize: 16,
              width: "100%",
              marginTop: 15,
              fontWeight: "bold",
              boxShadow: "0 3px 10px rgba(69, 104, 220, 0.3)",
              transition: "transform 0.2s, box-shadow 0.2s",
            }}
          >
            計算新租金
          </button>
        </div>
      </div>

      {/* 結果 */}
      {calculationResult && (
        <div
          ref={resultRef}
          style={{
            background: "#fff",
            borderRadius: "10px",
            boxShadow: "0 3px 10px rgba(0, 0, 0, 0.05)",
            padding: "20px",
            width: "100%",
            maxWidth: "700px",
            margin: "20px auto",
            border: "1px solid #eee"
          }}
        >
          <h2 style={{ 
            background: "linear-gradient(to right, #4D91FF, #17E7E7, #B859FF)",
            WebkitBackgroundClip: "text",
            backgroundClip: "text",
            color: "transparent",
            fontSize: "1.5em", 
            marginBottom: "15px",
            fontWeight: "600",
            borderBottom: "1px solid #eee",
            paddingBottom: "10px"
          }}>計算結果</h2>
          
          <table style={{
            width: "100%",
            borderCollapse: "collapse",
            borderRadius: "10px",
            overflow: "hidden"
          }}>
            <thead>
              <tr style={{ 
                borderBottom: "2px solid #f0f0f0", 
                background: "#f0f7ff" 
              }}>
                <th style={{ 
                  padding: "15px", 
                  textAlign: "left",
                  color: "rgb(52, 81, 175)",
                  fontWeight: "600",
                  fontSize: "1em"
                }}>項目</th>
                <th style={{ 
                  padding: "15px", 
                  textAlign: "right",
                  color: "rgb(52, 81, 175)",
                  fontWeight: "600",
                  fontSize: "1em"
                }}>數值</th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ background: "#fff", borderBottom: "1px solid #f0f0f0" }}>
                <td style={{ padding: "15px", color: "#555" }}>基本月租</td>
                <td style={{ padding: "15px", textAlign: "right", color: "#555" }}>
                  HK$ {calculationResult.baseRent.toFixed(2)}
                </td>
              </tr>
              <tr style={{ background: "#f9fafc", borderBottom: "1px solid #f0f0f0" }}>
                <td style={{ padding: "15px", color: "#555" }}>家庭人數</td>
                <td style={{ padding: "15px", textAlign: "right", color: "#555" }}>
                  {calculationResult.familySize}人
                </td>
              </tr>
              <tr style={{ background: "#fff", borderBottom: "1px solid #f0f0f0" }}>
                <td style={{ padding: "15px", color: "#555" }}>入息限額</td>
                <td style={{ padding: "15px", textAlign: "right", color: "#555" }}>
                  HK$ {calculationResult.limit.toFixed(2)}
                </td>
              </tr>
              <tr style={{ background: "#f9fafc", borderBottom: "1px solid #f0f0f0" }}>
                <td style={{ padding: "15px", color: "#555" }}>家庭收入</td>
                <td style={{ padding: "15px", textAlign: "right", color: "#555" }}>
                  HK$ {calculationResult.familyIncome.toFixed(2)}
                </td>
              </tr>
              <tr style={{ background: "#fff", borderBottom: "1px solid #f0f0f0" }}>
                <td style={{ padding: "15px", color: "#555" }}>超額倍數</td>
                <td style={{ padding: "15px", textAlign: "right", color: "#555" }}>
                  {calculationResult.multipleTimes.toFixed(2)}倍
                </td>
              </tr>
              <tr style={{ background: "#f9fafc", borderBottom: "1px solid #f0f0f0" }}>
                <td style={{ padding: "15px", color: "#555" }}>新租金倍數</td>
                <td style={{ padding: "15px", textAlign: "right", color: "#555" }}>
                  {calculationResult.newMultiplier}倍
                </td>
              </tr>
              <tr style={{ background: "#fff" }}>
                <td style={{ padding: "15px", color: "#555", fontWeight: "600" }}>新月租金額</td>
                <td style={{ 
                  padding: "15px", 
                  textAlign: "right", 
                  fontWeight: "bold",
                  background: "linear-gradient(to right, #4D91FF, #17E7E7, #B859FF)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  backgroundClip: "text",
                  color: "transparent",
                  fontSize: "1.3em"
                }}>
                  HK$ {calculationResult.newRent.toFixed(2)}
                </td>
              </tr>
            </tbody>
          </table>

          {calculationResult.needToMove && (
            <div style={{
              background: "rgba(255, 0, 0, 0.05)",
              borderRadius: "5px",
              padding: "15px",
              marginTop: "20px",
              border: "1px solid rgba(255, 0, 0, 0.2)"
            }}>
              <p style={{ color: "#d84040", fontWeight: "500", margin: 0 }}>
                ⚠️ 警告：由於您的家庭收入超過入息限額4倍，根據新制，若連續兩個申報期維持此收入水平，您將需要
                {calculationResult.yearsToMove}
                。搬離後可享有4年綠表資格購買居屋及綠置居。
              </p>
            </div>
          )}
        </div>
      )}


      <div style={{
        background: "#fff",
        borderRadius: "10px",
        boxShadow: "0 3px 10px rgba(0, 0, 0, 0.05)",
        padding: "20px",
        width: "100%",
        maxWidth: "700px",
        margin: "20px auto",
        border: "1px solid #eee"
      }}>
        <h2 style={{
          color: "rgb(52, 81, 175)",
          fontSize: "1.2em",
          marginBottom: "15px",
          fontWeight: "600"
        }}>新「公屋富戶政策」總結</h2>
        
        <div style={{
          background: "#f0f7ff",
          borderRadius: "10px",
          padding: "20px",
          color: "#333"
        }}>
          <ul style={{
            paddingLeft: "20px",
            color: "#333",
            lineHeight: "1.8",
            marginBottom: "0"
          }}>
            <li style={{ marginBottom: "10px" }}>
              <strong style={{color: "rgb(52, 81, 175)"}}>提高額外租金：</strong>
              入息超過限額2倍但不高於3倍的住戶需繳交2.5倍租金；超過3倍但不高於4倍需繳交3.5倍租金；超過4倍但不高於5倍需繳交4.5倍租金。
            </li>
            <li style={{ marginBottom: "10px" }}>
              <strong style={{color: "rgb(52, 81, 175)"}}>降低遷出門檻：</strong>
              若租戶在連續兩個申報周期(4年)內入息超過限額4倍但不多於5倍，必須遷出公屋。之前只有超過5倍才需遷出。
            </li>
            <li style={{ marginBottom: "10px" }}>
              <strong style={{color: "rgb(52, 81, 175)"}}>增加綠表比例：</strong>
              下一期居屋計劃中，綠表和白表比例由四六比改為五五比。
            </li>
            <li style={{ marginBottom: "10px" }}>
              <strong style={{color: "rgb(52, 81, 175)"}}>保留綠表資格：</strong>
              富戶自願遷出單位後，可保留綠表資格4年。
            </li>
            <li>
              <strong style={{color: "rgb(52, 81, 175)"}}>公屋租戶置業選擇：</strong>
              可申請新居屋、以綠表身份購買二手居屋或購買私樓。
            </li>
          </ul>
        </div>
      </div>
      
      {/* FAQ 部分 - 在计算结果之后显示 */}
      <div style={{
        background: "#fff",
        borderRadius: "10px",
        boxShadow: "0 3px 10px rgba(0, 0, 0, 0.05)",
        padding: "20px",
        width: "100%",
        maxWidth: "700px",
        margin: "20px auto",
        border: "1px solid #eee"
      }}>
        <h2 style={{
          color: "rgb(52, 81, 175)",
          fontSize: "1.2em",
          marginBottom: "15px",
          fontWeight: "600"
        }}>公屋租金常見問題</h2>
        
        <div style={{
          background: "#f0f7ff",
          borderRadius: "10px",
          padding: "20px",
          color: "#333"
        }}>
          <ul style={{
            paddingLeft: "20px",
            color: "#333",
            lineHeight: "1.8",
            marginBottom: "0"
          }}>
            <li style={{ marginBottom: "10px" }}>
              <strong style={{color: "rgb(52, 81, 175)"}}>什麼是「公屋富戶政策」？</strong><br />
              「公屋富戶政策」是房委會為公屋資源有效運用而設的政策，要求入息或資產超過限額的住戶繳交較高租金或遷出公屋。<br />
              <strong style={{color: "rgb(52, 81, 175)"}}>2025-2026入息及資產上限：</strong><br />
              <div style={{ marginTop: "10px", overflowX: "auto" }}>
                <table style={{ 
                  width: "100%", 
                  borderCollapse: "collapse", 
                  fontSize: "0.9em", 
                  border: "1px solid #ddd" 
                }}>
                  <thead>
                    <tr style={{ background: "#f0f7ff" }}>
                      <th style={{ 
                        padding: "8px", 
                        textAlign: "center", 
                        borderBottom: "1px solid #ddd", 
                        borderRight: "1px solid #ddd",
                        color: "rgb(52, 81, 175)"
                      }}>家庭人數</th>
                      <th style={{ 
                        padding: "8px", 
                        textAlign: "center", 
                        borderBottom: "1px solid #ddd", 
                        borderRight: "1px solid #ddd",
                        color: "rgb(52, 81, 175)"
                      }}>每月入息限額</th>
                      <th style={{ 
                        padding: "8px", 
                        textAlign: "center", 
                        borderBottom: "1px solid #ddd",
                        color: "rgb(52, 81, 175)"
                      }}>資產淨值限額</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ 
                        padding: "8px", 
                        textAlign: "center", 
                        borderBottom: "1px solid #ddd", 
                        borderRight: "1px solid #ddd" 
                      }}>1</td>
                      <td style={{ 
                        padding: "8px", 
                        textAlign: "center", 
                        borderBottom: "1px solid #ddd", 
                        borderRight: "1px solid #ddd" 
                      }}>$13,090</td>
                      <td style={{ 
                        padding: "8px", 
                        textAlign: "center", 
                        borderBottom: "1px solid #ddd" 
                      }}>$291,000</td>
                    </tr>
                    <tr style={{ background: "#f9f9f9" }}>
                      <td style={{ 
                        padding: "8px", 
                        textAlign: "center", 
                        borderBottom: "1px solid #ddd", 
                        borderRight: "1px solid #ddd" 
                      }}>2</td>
                      <td style={{ 
                        padding: "8px", 
                        textAlign: "center", 
                        borderBottom: "1px solid #ddd", 
                        borderRight: "1px solid #ddd" 
                      }}>$20,230</td>
                      <td style={{ 
                        padding: "8px", 
                        textAlign: "center", 
                        borderBottom: "1px solid #ddd" 
                      }}>$394,000</td>
                    </tr>
                    <tr>
                      <td style={{ 
                        padding: "8px", 
                        textAlign: "center", 
                        borderBottom: "1px solid #ddd", 
                        borderRight: "1px solid #ddd" 
                      }}>3</td>
                      <td style={{ 
                        padding: "8px", 
                        textAlign: "center", 
                        borderBottom: "1px solid #ddd", 
                        borderRight: "1px solid #ddd" 
                      }}>$25,100</td>
                      <td style={{ 
                        padding: "8px", 
                        textAlign: "center", 
                        borderBottom: "1px solid #ddd" 
                      }}>$514,000</td>
                    </tr>
                    <tr style={{ background: "#f9f9f9" }}>
                      <td style={{ 
                        padding: "8px", 
                        textAlign: "center", 
                        borderBottom: "1px solid #ddd", 
                        borderRight: "1px solid #ddd" 
                      }}>4</td>
                      <td style={{ 
                        padding: "8px", 
                        textAlign: "center", 
                        borderBottom: "1px solid #ddd", 
                        borderRight: "1px solid #ddd" 
                      }}>$31,000</td>
                      <td style={{ 
                        padding: "8px", 
                        textAlign: "center", 
                        borderBottom: "1px solid #ddd" 
                      }}>$600,000</td>
                    </tr>
                    <tr>
                      <td style={{ 
                        padding: "8px", 
                        textAlign: "center", 
                        borderBottom: "1px solid #ddd", 
                        borderRight: "1px solid #ddd" 
                      }}>5</td>
                      <td style={{ 
                        padding: "8px", 
                        textAlign: "center", 
                        borderBottom: "1px solid #ddd", 
                        borderRight: "1px solid #ddd" 
                      }}>$38,650</td>
                      <td style={{ 
                        padding: "8px", 
                        textAlign: "center", 
                        borderBottom: "1px solid #ddd" 
                      }}>$666,000</td>
                    </tr>
                    <tr style={{ background: "#f9f9f9" }}>
                      <td style={{ 
                        padding: "8px", 
                        textAlign: "center", 
                        borderBottom: "1px solid #ddd", 
                        borderRight: "1px solid #ddd" 
                      }}>6</td>
                      <td style={{ 
                        padding: "8px", 
                        textAlign: "center", 
                        borderBottom: "1px solid #ddd", 
                        borderRight: "1px solid #ddd" 
                      }}>$45,440</td>
                      <td style={{ 
                        padding: "8px", 
                        textAlign: "center", 
                        borderBottom: "1px solid #ddd" 
                      }}>$721,000</td>
                    </tr>
                    <tr>
                      <td style={{ 
                        padding: "8px", 
                        textAlign: "center", 
                        borderBottom: "1px solid #ddd", 
                        borderRight: "1px solid #ddd" 
                      }}>7</td>
                      <td style={{ 
                        padding: "8px", 
                        textAlign: "center", 
                        borderBottom: "1px solid #ddd", 
                        borderRight: "1px solid #ddd" 
                      }}>$49,930</td>
                      <td style={{ 
                        padding: "8px", 
                        textAlign: "center", 
                        borderBottom: "1px solid #ddd" 
                      }}>$770,000</td>
                    </tr>
                    <tr style={{ background: "#f9f9f9" }}>
                      <td style={{ 
                        padding: "8px", 
                        textAlign: "center", 
                        borderBottom: "1px solid #ddd", 
                        borderRight: "1px solid #ddd" 
                      }}>8</td>
                      <td style={{ 
                        padding: "8px", 
                        textAlign: "center", 
                        borderBottom: "1px solid #ddd", 
                        borderRight: "1px solid #ddd" 
                      }}>$55,830</td>
                      <td style={{ 
                        padding: "8px", 
                        textAlign: "center", 
                        borderBottom: "1px solid #ddd" 
                      }}>$805,000</td>
                    </tr>
                    <tr>
                      <td style={{ 
                        padding: "8px", 
                        textAlign: "center", 
                        borderBottom: "1px solid #ddd", 
                        borderRight: "1px solid #ddd" 
                      }}>9</td>
                      <td style={{ 
                        padding: "8px", 
                        textAlign: "center", 
                        borderBottom: "1px solid #ddd", 
                        borderRight: "1px solid #ddd" 
                      }}>$61,570</td>
                      <td style={{ 
                        padding: "8px", 
                        textAlign: "center", 
                        borderBottom: "1px solid #ddd" 
                      }}>$892,000</td>
                    </tr>
                    <tr style={{ background: "#f9f9f9" }}>
                      <td style={{ 
                        padding: "8px", 
                        textAlign: "center", 
                        borderBottom: "1px solid #ddd", 
                        borderRight: "1px solid #ddd" 
                      }}>10+</td>
                      <td style={{ 
                        padding: "8px", 
                        textAlign: "center", 
                        borderBottom: "1px solid #ddd", 
                        borderRight: "1px solid #ddd" 
                      }}>$67,180</td>
                      <td style={{ 
                        padding: "8px", 
                        textAlign: "center", 
                        borderBottom: "1px solid #ddd" 
                      }}>$961,000</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>
            <li style={{ marginBottom: "10px" }}>
              <strong style={{color: "rgb(52, 81, 175)"}}>如何申報入息及資產？</strong><br />
              公屋住戶需每兩年申報一次家庭入息及資產。房委會會向住戶發出申報表格，住戶須如實填報。
            </li>
            <li style={{ marginBottom: "10px" }}>
              <strong style={{color: "rgb(52, 81, 175)"}}>如入息超過限額會有什麼後果？</strong><br />
              視乎超出幅度，住戶可能需要繳交額外租金或在指定時間內遷出公屋。
            </li>
            <li style={{ marginBottom: "10px" }}>
              <strong style={{color: "rgb(52, 81, 175)"}}>誰可獲得豁免？</strong><br />
              年滿60歲的長者、領取傷殘津貼的人士及領取綜援的家庭可獲豁免「公屋富戶政策」。
            </li>
            <li>
              <strong style={{color: "rgb(52, 81, 175)"}}>如不遵從政策會有什麼後果？</strong><br />
              若住戶未能繳交額外租金或拒絕遷出，房委會可能會採取法律行動，包括收回單位。
            </li>
          </ul>
        </div>
      </div>

      <div style={{
        background: "#fff",
        borderRadius: "10px",
        boxShadow: "0 3px 10px rgba(0, 0, 0, 0.05)",
        padding: "20px",
        width: "100%",
        maxWidth: "700px",
        margin: "20px auto",
        border: "1px solid #eee"
      }}>
        <h3 style={{
          color: "rgb(52, 81, 175)",
          fontSize: "1.2em",
          marginBottom: "15px",
          fontWeight: "600",
          borderBottom: "1px solid #eee",
          paddingBottom: "10px"
        }}>新公屋富戶政策租金級別表</h3>
        
        <div style={{
          overflow: "auto"
        }}>
          <table style={{
            width: "100%",
            borderCollapse: "collapse",
            marginTop: "10px"
          }}>
            <thead>
              <tr style={{
                background: "#f0f7ff",
                color: "rgb(52, 81, 175)"
              }}>
                <th style={{
                  padding: "12px 15px",
                  textAlign: "left",
                  borderBottom: "1px solid #eee"
                }}>入息超出程度</th>
                <th style={{
                  padding: "12px 15px",
                  textAlign: "left",
                  borderBottom: "1px solid #eee"
                }}>應繳租金</th>
                <th style={{
                  padding: "12px 15px",
                  textAlign: "left",
                  borderBottom: "1px solid #eee"
                }}>是否須遷出</th>
              </tr>
            </thead>
            <tbody>
              <tr style={{
                background: "#fff"
              }}>
                <td style={{
                  padding: "12px 15px",
                  borderBottom: "1px solid #eee"
                }}>不超過1倍入息限額</td>
                <td style={{
                  padding: "12px 15px",
                  borderBottom: "1px solid #eee"
                }}>標準租金</td>
                <td style={{
                  padding: "12px 15px",
                  borderBottom: "1px solid #eee"
                }}>否</td>
              </tr>
              <tr style={{
                background: "#f9f9f9"
              }}>
                <td style={{
                  padding: "12px 15px",
                  borderBottom: "1px solid #eee"
                }}>超過1倍但不多於2倍入息限額</td>
                <td style={{
                  padding: "12px 15px",
                  borderBottom: "1px solid #eee"
                }}>倍半租金</td>
                <td style={{
                  padding: "12px 15px",
                  borderBottom: "1px solid #eee"
                }}>否</td>
              </tr>
              <tr style={{
                background: "#fff"
              }}>
                <td style={{
                  padding: "12px 15px",
                  borderBottom: "1px solid #eee"
                }}>超過2倍但不多於3倍入息限額</td>
                <td style={{
                  padding: "12px 15px",
                  borderBottom: "1px solid #eee",
                  fontWeight: "bold",
                  color: "rgb(52, 81, 175)"
                }}>2.5倍租金（新）</td>
                <td style={{
                  padding: "12px 15px",
                  borderBottom: "1px solid #eee"
                }}>否</td>
              </tr>
              <tr style={{
                background: "#f9f9f9"
              }}>
                <td style={{
                  padding: "12px 15px",
                  borderBottom: "1px solid #eee"
                }}>超過3倍但不多於4倍入息限額</td>
                <td style={{
                  padding: "12px 15px",
                  borderBottom: "1px solid #eee",
                  fontWeight: "bold",
                  color: "rgb(52, 81, 175)"
                }}>3.5倍租金（新）</td>
                <td style={{
                  padding: "12px 15px",
                  borderBottom: "1px solid #eee"
                }}>否</td>
              </tr>
              <tr style={{
                background: "#fff"
              }}>
                <td style={{
                  padding: "12px 15px",
                  borderBottom: "1px solid #eee"
                }}>超過4倍但不多於5倍入息限額</td>
                <td style={{
                  padding: "12px 15px",
                  borderBottom: "1px solid #eee",
                  fontWeight: "bold",
                  color: "rgb(52, 81, 175)"
                }}>4.5倍租金（新）</td>
                <td style={{
                  padding: "12px 15px",
                  borderBottom: "1px solid #eee",
                  fontWeight: "bold",
                  color: "#ff5252"
                }}>連續兩個申報周期後須遷出（新）</td>
              </tr>
              <tr style={{
                background: "#f9f9f9"
              }}>
                <td style={{
                  padding: "12px 15px",
                  borderBottom: "1px solid #eee"
                }}>超過5倍入息限額</td>
                <td style={{
                  padding: "12px 15px",
                  borderBottom: "1px solid #eee"
                }}>不適用</td>
                <td style={{
                  padding: "12px 15px",
                  borderBottom: "1px solid #eee",
                  fontWeight: "bold",
                  color: "#ff5252"
                }}>必須遷出</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Markdown風格的頁腳 */}
      <hr />
      <div style={{
        textAlign: "center",
        color: "#999",
        fontSize: "0.8em",
        margin: "30px 0 10px",
        padding: "10px"
      }}>
        備註：本計算器僅作參考，實際租金調整以房委會最終決定為準。
        <div style={{ marginTop: 5 }}>
          © 1MINS.AI 提供
        </div>
      </div>
    </div>
    </>
    
  );
}

export default PublicHousingRentCalculator;
