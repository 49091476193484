import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const MobileNav = ({ isOpen, toggleMenu }) => {
  const [activeDropdown, setActiveDropdown] = useState(null);
  
  // 關閉菜單時重置下拉菜單狀態
  useEffect(() => {
    if (!isOpen) {
      setActiveDropdown(null);
    }
  }, [isOpen]);
  
  // 處理下拉菜單點擊
  const handleDropdownClick = (index, e) => {
    e.preventDefault();
    setActiveDropdown(activeDropdown === index ? null : index);
  };
  
  // 處理導航鏈接點擊
  const handleLinkClick = () => {
    toggleMenu(false);
    setActiveDropdown(null);
  };
  
  // 導航數據
  const navItems = [
    {
      title: '首頁',
      path: '/',
      isDropdown: false
    },
    {
      title: '功能',
      path: '#features',
      isDropdown: true,
      items: [
        { title: '智能寫作', path: '/#ai-writing' },
        { title: '圖像生成', path: '/#image-generation' },
        { title: '內容翻譯', path: '/#content-translation' },
        { title: '數據分析', path: '/#data-analysis' }
      ]
    },
    {
      title: '生態系統',
      path: '#ecosystem',
      isDropdown: true,
      items: [
        { title: '內容創作', path: '/#content-creation' },
        { title: '視覺設計', path: '/#visual-design' },
        { title: '多媒體處理', path: '/#multimedia' },
        { title: '數據智能', path: '/#data-intelligence' }
      ]
    },
    {
      title: '資源',
      path: '#resources',
      isDropdown: true,
      items: [
        { title: '部落格', path: '/#blog' },
        { title: '教程', path: '/#tutorials' },
        { title: '案例研究', path: '/#case-studies' },
        { title: '文檔', path: '/#documentation' }
      ]
    },
    {
      title: '公屋租金計算器',
      path: '/rent-calculator',
      isDropdown: false
    },
    {
      title: '關於我們',
      path: '/about',
      isDropdown: false
    },
    
    
  ];
  
  return (
    <div className={`mobile-menu ${isOpen ? 'active' : ''}`}>
      <div className="mobile-menu-container">
        <ul className="mobile-nav-items">
          {navItems.map((item, index) => (
            <li key={index} className={`mobile-nav-item ${item.isDropdown ? 'dropdown' : ''} ${activeDropdown === index ? 'active' : ''}`}>
              {item.isDropdown ? (
                <>
                  <a 
                    href={item.path} 
                    onClick={(e) => handleDropdownClick(index, e)}
                    className="mobile-nav-link"
                  >
                    {item.title} <span className={`arrow ${activeDropdown === index ? 'active' : ''}`}>▼</span>
                  </a>
                  <div className={`mobile-dropdown-content ${activeDropdown === index ? 'active' : ''}`}>
                    {item.items.map((subItem, subIndex) => (
                      <Link 
                        key={subIndex} 
                        to={subItem.path} 
                        onClick={handleLinkClick}
                        className="mobile-dropdown-link"
                      >
                        {subItem.title}
                      </Link>
                    ))}
                  </div>
                </>
              ) : (
                <Link 
                  to={item.path} 
                  onClick={handleLinkClick}
                  className="mobile-nav-link"
                >
                  {item.title}
                </Link>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MobileNav; 